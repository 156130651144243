import React from 'react';
import styled from 'styled-components';

import { Container } from '@components/global';
import ExternalLink from '@common/ExternalLink';
import { ReactComponent as Heart } from '@images/penguin/heart.svg';

import InstagramIcon from '@static/icons/instagram.svg';
import TwitterIcon from '@static/icons/twitter.svg';

const SOCIAL = [
  {
    icon: InstagramIcon,
    link: 'https://instagram.com/silicane',
  },
  {
    icon: TwitterIcon,
    link: 'https://twitter.com/silicane_com',
  },
];

const Footer = () => (
  <React.Fragment>
        <Art>
          {Heart()}
        </Art>
        <FooterWrapper>
          <StyledContainer>
            <Copyright>
              <h2 style={{ color: 'white' }}>Paradax</h2>
              <span>
                Illustrations by
                {` `}
                <ExternalLink href="https://silicane.com/">
                  Sander Nagtegaal
                </ExternalLink>
              </span>
            </Copyright>
            <SocialIcons>
              {SOCIAL.map(({ icon, link, i }) => (
                <ExternalLink key={icon} href={link}>
                  <img src={icon} alt="link" />
                </ExternalLink>
              ))}
            </SocialIcons>
          </StyledContainer>
        </FooterWrapper>
      </React.Fragment>
);

const SocialIcons = styled.div`
  display: flex;

  img {
    margin: 0 8px;
    width: 24px;
    height: 24px;
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-top: 40px;
  }
`;

const FooterWrapper = styled.footer`
  background-color: ${props => props.theme.color.primary};
  padding: 32px 0;
`;

const Copyright = styled.div`
  font-family: ${props => props.theme.font.secondary};
  ${props => props.theme.font_size.small};
  color: ${props => props.theme.color.black.regular};

  a {
    text-decoration: none;
    color: inherit;
  }
`;

const Art = styled.figure`
  text-align: center;
  padding: 5%;
  svg { 
    max-width: 400px;
    margin: 0 auto;
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${props => props.theme.screen.sm}) {
    flex-direction: column;
    text-align: center;
  }
`;

export default Footer;
