import React from 'react';
import styled from 'styled-components';

import { Section, Container } from '@components/global';

import { ReactComponent as Social } from '@images/penguin/social.svg';

const UsedBy = () => (
  <Section id="brands" accent>
    <StyledContainer>
      <Art>
        {Social()}
      </Art>
    </StyledContainer>
  </Section>
);

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: flex-end;
  position: relative;

  @media (max-width: ${props => props.theme.screen.md}) {
    justify-content: center;
  }
`;

const Art = styled.figure`
  width: 600px;
  position: relative;
  top: -12%;
  margin: auto;
  @media (max-width: ${props => props.theme.screen.lg}) {
    width: 500px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    width: 300px;
  }
`;

export default UsedBy;
