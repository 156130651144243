import React from 'react';

import { Section, Container } from '@components/global';

import FaqItem from '@common/FaqItem';
import ExternalLink from '@common/ExternalLink';

const FAQS = [
  {
    title: 'What is the story behind Paradax?',
    content: () => (
      <>
        The Paradax penguin was first spotted around 1990 on several trains in The Netherlands.
        A local graffiti artist created the symbol as an alternative signature, rather than using a written tag name.
        The aim was to prevent handwriting experts to find out who he was. It worked.
      </>
    ),
  },
  {
    title: 'Is it possible that I have seen Paradax before?',
    content: () => (
      <>
        Absolutely. Paradax has lived several lives.
        Besides his graffiti career, he served as the logo of a company called <ExternalLink href="https:/peecho.com/">Peecho</ExternalLink>.
        That's an early startup from Amsterdam. Sadly, he was fired in 2019 and went on as the unemployed and slightly depressed penguin he is today.
      </>
    ),
  },
  {
    title: 'Why is Paradax known as the paranoid penguin?',
    content: () => (
      <>
        After he got fired at his previous job, Paradax became a lonely penguin. He fell into depression and paranoia.
        Look into his eyes, and you will see.
      </>
    ),
  },
  {
    title: 'Can I buy Paradax merchandise?',
    content: () => (
      <>
        Not yet. But I have heard that this may be possible soon. Stay tuned!
      </>
    ),
  },
];

const Faq = () => (
  <Section id="faq">
    <Container>
      <h1 style={{ marginBottom: 40 }}>Frequently Asked Questions</h1>
      <div>
        {FAQS.map(({ title, content }) => (
          <FaqItem title={title} key={title}>
            {content()}
          </FaqItem>
        ))}
      </div>
    </Container>
  </Section>
);

export default Faq;
