import React from 'react';
import styled from 'styled-components';

import { Section, Container } from '@components/global';

import { ReactComponent as Penguin } from '@images/penguin/penguin.svg';
import { ReactComponent as Game } from '@images/penguin/game.svg';
import { ReactComponent as Sherlock } from '@images/penguin/sherlock.svg';


const About = () => (
      <Section id="about">
        <Container>
          <Grid>
            <div>
              <h2>Fair game</h2>
              <p>
                Paradax is the penguin that you have been waiting for.
                A former startup logo, he is now free to roam.
              </p>
            </div>
            <Art>
              {Game()}
            </Art>
          </Grid>
          <Grid inverse>
            <Art>
              {Penguin()}
            </Art>
            <div>
              <h2>The future is bright</h2>
              <p>
                The future of Paradax is undetermined. He may be in a children's book,
                or part of a comic series, or sell his soul to a merchandise industrialist.
              </p>
            </div>
          </Grid>
          <Grid>
            <div>
              <h2>You are invited</h2>
              <p>
                Let us know what Paradax should be when he grows up. <br />You know where to find us.
              </p>
            </div>
            <Art>
              {Sherlock()}
            </Art>
          </Grid>
        </Container>
      </Section>
);

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 40px;
  text-align: right;
  align-items: center;
  justify-items: center;
  margin: 24px 0;

  ${props =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 2fr 3fr;
  `}

  h2 {
    margin-bottom: 16px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

    ${props =>
      props.inverse &&
      `
        ${Art} {
          order: 2;
        }
    `}
  }
`;

const Art = styled.figure`
  margin: 0;
  padding: 10%;
  max-width: 380px;
  width: 100%;
`;

export default About;
